import React, { useEffect } from 'react';
import neaLogo from '../../../assets/NEA-logo.png';
import emblem from '../../../assets/emblem.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../features/auth/authSlice';
import { Dropdown, Space } from 'antd';
import { formatImgPath } from '../../../utils';

function Header({ isUserLoggedIn }) {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);

	async function handleLogout(e) {
		e.preventDefault();
		await dispatch(logout());
	}

	const items = [
		{
			key: '4',
			label: (
				<Link to='#' onClick={(e) => handleLogout(e)}>
					Logout
				</Link>
			),
			icon: (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'>
					<g clipPath='url(#clip0_4865_3831)'>
						<path
							d='M10.09 15.59L11.5 17L16.5 12L11.5 7L10.09 8.41L12.67 11H3V13H12.67L10.09 15.59ZM19 3H5C3.89 3 3 3.9 3 5V9H5V5H19V19H5V15H3V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z'
							fill='black'
						/>
					</g>
					<defs>
						<clipPath id='clip0_4865_3831'>
							<rect width='24' height='24' fill='white' />
						</clipPath>
					</defs>
				</svg>
			),
		},
	];

	useEffect(() => {}, [isUserLoggedIn]);

	return (
		<div className='flex justify-between items-center bg-white w-full p-1'>
			<Link to='/' className='flex items-center'>
				<img
					loading='lazy'
					src={emblem}
					className='ml-0 lg:ml-[1.97rem] object-contain w-[3.9375rem] h-[3.49rem]'
					alt='kenya Emplem'
				/>

				<img
					loading='lazy'
					src={neaLogo}
					className='ml-[1.06rem] object-cover w-[9.3125rem] h-[2.73813rem]'
					alt='NEAIMIS Logo'
				/>
			</Link>

			{isUserLoggedIn ? (
				<div className='mr-[20px] rounded-full border border-darkBlue ml-10'>
					<Dropdown
						overlayStyle={{
							width: '250px',
						}}
						menu={{
							items,
						}}
						placement='bottomLeft'>
						<img
							loading='lazy'
							className='w-[50px] h-[50px] object-cover rounded-full'
							src={
								user?.usrProfileImage
									? formatImgPath(user?.usrProfileImage)
									: 'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png'
							}
							alt='user-profile'
						/>
					</Dropdown>
				</div>
			) : (
				<>
					<div className='flex items-center gap-[.5rem] mr-[1.97rem]'>
						<Link type='button' className='login-home-btn' to='/login'>
							Log in
						</Link>

						<Link to='/registration/type' className='signup-home-btn'>
							<Space>Sign up</Space>
						</Link>
					</div>
				</>
			)}
		</div>
	);
}

export default Header;
