import React, { useEffect, useRef, useState } from 'react';
import '../agency_signup_components/details.css';
import { Form, Input, Radio, Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	createInstitution,
	setSignupEmployerDetails,
} from '../../features/auth/authSlice';
import PhoneInput from 'react-phone-input-2';
import { fetchNeaOffices } from '../../features/global/globalSlice';
import { fetchEconomicActivitys } from '../../features/dropDowns/dropDownSlice';
import { customToast } from '../../notifications';

function EmployerDetail() {
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { institution, authLoading, signUpEmployerDetails } = useSelector(
		(state) => state.auth
	);
	const { globalLoading, nea } = useSelector((state) => state.global);
	const { dropDownLoading, economicActivities } = useSelector(
		(state) => state.dropDown
	);

	const [instBody, setInstBody] = useState(
		signUpEmployerDetails?.instLearning ?? false
	);

	const onFinish = async (data) => {
		let neaObj = nea?.find((item) => item?.instId === data.instNearNeaOffice);
		data.instWorkTel =
			data.instWorkTel.charAt(3) === '0'
				? data.instWorkTel.replace('0', '')
				: data.instWorkTel;

		data.instNearNeaOffice = Number(data?.instNearNeaOffice);

		const res = await dispatch(
			createInstitution({
				instId:
					Object?.keys(institution)?.length > 0
						? institution?.jsonData?.instId
						: null,
				instName: data.instName,
				instRegCertNo: data.instRegCertNo,
				instKraPin: data.instKraPin,
				instWorkTel: data.instWorkTel,
				instEmail: data.instEmail,
				instEconomic: data.instEconomic,
				instType: 'EMPLOYER',
				instStatus: 'PENDING_VERIFICATION',
				instPassword: 1234,
				instRoleId: 5,
				instNeaId: data.instNearNeaOffice,
				instNearNeaOffice: neaObj?.instName,
				instLearning: instBody,
			})
		);

		if (res?.payload?.success) {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'success',
			});
			await navigate('/verify-email');
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
				header: 'Error',
			});
		}
	};

	const handleValuesChange = (changedValues, allValues) => {
		dispatch(setSignupEmployerDetails(allValues));
	};

	async function fetchNea() {
		await dispatch(fetchNeaOffices());
		await dispatch(fetchEconomicActivitys());
	}

	useEffect(() => {
		fetchNea();
	}, []);

	return (
		<>
			<div className='flex items-center justify-center'>
				<div className='w-full lg:w-[61.25rem]'>
					<div className='flex flex-col w-full px-3 lg:px-[52px] p-3'>
						<div className='bg-white mt-[3.75rem] pt-[3.75rem] pb-[3.19rem] rounded-[.5625rem]'>
							<h2 className='setup-title text-center'>
								Sign up to <span className='text-blueDark'>hire </span>
								new talent.
							</h2>
							<p className='heading_heading_5 mt-[1rem] text-center'>
								Fill in your details to create an account.
							</p>
						</div>

						<Form
							layout='vertical'
							ref={formRef}
							name='control-ref'
							onFinish={onFinish}
							style={{
								maxWidth: '100%',
							}}
							initialValues={signUpEmployerDetails}
							onValuesChange={handleValuesChange}
							form={form}>
							<div className='mt-[1rem] p-3 !pr-0 lg:!pr-10 lg:p-0 py-[3.5rem] flex flex-col justify-center w-full bg-white rounded-[.5625rem]'>
								<div className='my-[2.25rem] px-[1.37rem] flex items-start justify-start '>
									<div className='w-[3.45rem]'>
										<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
											<span className='paragraph_paragraph_bb08'>1</span>
										</div>
									</div>

									<div className='w-full flex flex-col ml-[1.31rem]'>
										<span className='paragraph_paragraph_bb13'>
											Organization details
										</span>

										<Form.Item
											className='table-form-item pb-[1.25rem]'
											name='instName'
											label='Employer name'
											rules={[
												{
													required: true,
													message: 'Please add Employer name',
												},
											]}>
											<Input className='input' />
										</Form.Item>

										<div className='grid grid-cols-1 lg:grid-cols-2 pb-[2.5rem] gap-[1.25rem] w-full'>
											<Form.Item
												className='table-form-item'
												name='instRegCertNo'
												label='Business Reg No'
												rules={[
													{
														required: true,
														message: 'Please add business reg no',
													},
												]}>
												<Input className='input' />
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='instKraPin'
												label='Business KRA PIN'
												rules={[
													{
														required: true,
														message: 'Please add KRA pin',
													},
													{
														pattern: /^[A-Z0-9]{11}$/,
														message:
															'Invalid Kra Pin format. A valid Kra Pin should be 11 alphanumeric characters.',
													},
												]}>
												<Input className='input' />
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='instEmail'
												label='Official Email Address'
												extra='Official email should ONLY be for the organization'
												rules={[
													{
														required: true,
														message: 'Please add official email address',
													},
													{
														type: 'email',
														message: 'Please enter a valid email address',
													},
												]}>
												<Input type='email' className='input' />
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='instWorkTel'
												label='Office Phone No'
												rules={[
													{
														required: true,
														message: 'Please add office phone no',
													},
													{
														validator: (_, value) => {
															if (value && value.length === 12) {
																return Promise.resolve();
															}
															return Promise.reject(
																new Error(
																	'Phone number must be exactly 10 characters'
																)
															);
														},
													},
												]}>
												<PhoneInput
													country='ke'
													countryCodeEditable={false}
													className='input'
												/>
											</Form.Item>
										</div>
									</div>
								</div>
							</div>

							<div className='mt-[1rem] p-3 !pr-0 lg:!pr-10 lg:p-0 py-[3.5rem] flex flex-col justify-center w-full bg-white rounded-[.5625rem]'>
								<div className='my-[2.25rem] px-[1.37rem] flex items-start justify-start '>
									<div className='w-[3.45rem]'>
										<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
											<span className='paragraph_paragraph_bb08'>2</span>
										</div>
									</div>

									<div className='w-full flex flex-col ml-[1.31rem]'>
										<span className='paragraph_paragraph_bb13'>
											Additional details
										</span>

										<div className='grid grid-cols-1 pb-[2.5rem] gap-[1.25rem] w-full'>
											<Form.Item
												className='table-form-item'
												rules={[
													{
														required: true,
														message: 'Please add an economic activity',
													},
												]}
												name='instEconomic'
												label='Economic Activity'>
												<Select
													suffixIcon={
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'>
															<path
																d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
																fill='#212121'
															/>
														</svg>
													}
													showSearch
													loading={dropDownLoading}
													style={{
														width: '100%',
														height: '44px',
													}}
													placeholder='Search to Select'
													optionFilterProp='children'
													filterOption={(input, option) =>
														(option?.label?.toLocaleLowerCase() ?? '').includes(
															input?.toLocaleLowerCase()
														)
													}
													filterSort={(optionA, optionB) =>
														(optionA?.label ?? '')
															.toLowerCase()
															.localeCompare(
																(optionB?.label ?? '').toLowerCase()
															)
													}
													options={
														economicActivities?.length > 0 &&
														economicActivities?.map((item) => ({
															value: item.ecoName,
															label: item?.ecoName,
														}))
													}
												/>
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='instNearNeaOffice'
												rules={[
													{
														required: true,
														message: 'Select your nearest NEA office',
													},
												]}
												label='Nearest NEA Office'>
												<Select
													suffixIcon={
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'>
															<path
																d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
																fill='#212121'
															/>
														</svg>
													}
													showSearch
													loading={globalLoading}
													style={{
														width: '100%',
														height: '44px',
													}}
													placeholder='Search to Select'
													optionFilterProp='children'
													filterOption={(input, option) =>
														(option?.label?.toLocaleLowerCase() ?? '').includes(
															input?.toLocaleLowerCase()
														)
													}
													filterSort={(optionA, optionB) =>
														(optionA?.label ?? '')
															.toLowerCase()
															.localeCompare(
																(optionB?.label ?? '').toLowerCase()
															)
													}
													options={
														nea?.length > 0 &&
														nea?.map((office) => ({
															value: office.instId,
															label: `${office.instName}`,
														}))
													}
												/>
											</Form.Item>

											<Form.Item
												className='table-form-item'
												name='instPhysicalAddress'
												label='Office Location'
												rules={[
													{
														required: true,
														message:
															'Add building, street name or which road you are located',
													},
												]}>
												<Input className='input' />
											</Form.Item>

											<Form.Item
												className='table-form-item'
												label='Are you a learning institution or Examination body?'
												rules={[
													{
														required: false,
														message:
															'Are you a learning institution or Examination body?',
													},
												]}
												name='instLearning'>
												<Radio.Group
													onChange={(e) => setInstBody(e.target.value)}
													defaultValue={instBody}>
													<Radio value={true}>Yes</Radio>
													<Radio value={false}>No</Radio>
												</Radio.Group>
											</Form.Item>
										</div>
									</div>
								</div>
							</div>

							<div className='text-center mt-10'>
								<button
									disabled={authLoading}
									style={{
										border: '0.5px solid #FFF',
									}}
									className='text-white text-[18px] font-bold font-dmSans
                    w-[299px] h-[53px] px-16 py-[19px] bg-blueDark rounded-[38px] 
                    justify-center items-center gap-2.5 inline-flex'>
									{authLoading ? <Spin className='text-white' /> : 'Next'}
								</button>
							</div>

							<div
								style={{
									opacity: 0.8,
								}}
								className='flex justify-center items-center text-center mt-[2.5rem] mb-[50px]'>
								<button
									onClick={() => navigate('/login')}
									className='flex items-center justify-center paragraph_paragraph_2 !text-white'>
									<span>Already have an account?</span>
									<span className='ml-[.3rem] underline'>Login</span>
								</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}

export default EmployerDetail;