import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Spin } from 'antd';
import {
	handleNextStep,
	handleRegNextStep,
} from '../../../../features/profile/profileSlice';
import {
	fetchEducations,
	fetchProfessionalQualifications,
	saveEducation,
	saveProfessionalQualification,
} from '../../../../features/edu/eduSlice';
import eduImg from '../../../../assets/edit_edu.png';
import AcademicTableForm from './education_forms/AcademicForm';
import ProfessionalTableForm from './education_forms/ProfessionalForm';
import {
	fetchAwards,
	fetchInstitutionTypes,
	fetchProfessionalBodies,
	fetchStudyAreas,
} from '../../../../features/dropDowns/dropDownSlice';

export default function EducationInfo({ buildprofile }) {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { eduLoading, educations, professionalQualifications } = useSelector(
		(state) => state.edu
	);

	const [addEducation, setaddEducation] = useState(false);
	const [educationList, seteducationList] = useState(educations);
	const [skip, setskip] = useState(false);

	const usrObj = {
		usrId: user?.usrId,
	};

	function onSkipChange(e) {
		setskip(e.target.checked);
	}

	const handleQualificationsChange = async (values) => {
		let copyObj = {
			...values,
			pqlMembershipBody: values?.pqlMembershipBody?.toString(),
		};
		await dispatch(saveProfessionalQualification(copyObj));
	};

	async function handleSKip() {
		const eduObj = {
			eduInstType: 'None',
			eduInstName: 'None',
			eduUsrId: user?.usrId,
		};
		await dispatch(saveEducation(eduObj));
		await dispatch(fetchEducations(usrObj));
		(await buildprofile) ? handleProfileGoNext() : handleGoNext();
	}

	async function handleGoNext() {
		await educations?.forEach((item) => {
			dispatch(saveEducation(item));
		});
		await professionalQualifications?.forEach((item) => {
			handleQualificationsChange(item);
		});
		await dispatch(handleRegNextStep());
	}

	async function handleProfileGoNext() {
		await educations?.forEach((item) => {
			dispatch(saveEducation(item));
		});
		await professionalQualifications?.forEach((item) => {
			handleQualificationsChange(item);
		});
		await dispatch(handleNextStep());
	}

	async function handleFetchEducations() {
		const resp = await dispatch(fetchEducations(usrObj));

		if (resp?.payload?.success) {
			await seteducationList(resp?.payload?.data?.result);
		}
		await dispatch(fetchProfessionalQualifications(usrObj));
	}

	async function handleDropdownOptions() {
		await dispatch(fetchInstitutionTypes());
		await dispatch(fetchAwards());
		await dispatch(fetchStudyAreas());
		await dispatch(fetchProfessionalBodies());
	}

	useEffect(() => {}, [educationList, professionalQualifications]);

	useEffect(() => {
		seteducationList(educations);
	}, [educations]);

	useEffect(() => {
		handleFetchEducations();
		handleDropdownOptions();
	}, []);

	return (
		<>
			{educationList?.length ||
			professionalQualifications?.length ||
			addEducation ? (
				<>
					<div className='flex justify-center items-center w-full'>
						<div
							style={{
								borderRadius: '0.5rem',
								border: '1px solid #147CBC',
							}}
							className='mt-10 !p-[2.75rem] !w-full xl:!w-[80.875rem] bg-white px-0 lg:px-[2.75rem]'>
							<div className='flex justify-center items-center pt-[3.56rem]'>
								<div
									style={{
										background:
											'linear-gradient(180deg, rgba(0, 0, 0, 0.00) -50%, #1844B5 50%)',
									}}
									className='w-[6.25rem] h-[6.25rem] rounded-full p-[1.81rem]'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='42'
										height='42'
										viewBox='0 0 42 42'
										fill='none'>
										<path
											d='M1.3125 15.75L21 5.25L40.6875 15.75L21 26.25L1.3125 15.75Z'
											stroke='white'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M21 15.75L30.1875 20.6505V39.375'
											stroke='white'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M35.4375 18.5508V27.2822C35.4382 27.6029 35.3215 27.9128 35.1094 28.1534C33.2555 30.2189 28.7273 34.1252 21 34.1252C13.2727 34.1252 8.74782 30.2189 6.89063 28.1534C6.67849 27.9128 6.56176 27.6029 6.5625 27.2822V18.5508'
											stroke='white'
											stroke-width='2'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
									</svg>
								</div>
							</div>
							<h3 className='heading_heading_2 w-full text-center mt-[2rem]'>
								<span>Education</span>
							</h3>
							<p className='heading_heading_5 mt-[1.75rem] mb-[2.25rem] text-center'>
								Enter your professional/academic qualifications below
							</p>
							<h2 className='heading_heading_5 mt-[4.69rem]'>Academic</h2>
							<AcademicTableForm />

							<h2 className='heading_heading_5 mt-[5.88rem]'>
								Training/ Professional
							</h2>
							<ProfessionalTableForm />

							<div className='mt-20'></div>

							<div className='flex flex-col justify-center items-center w-full'>
								<button
									disabled={educationList?.length === 0 || eduLoading}
									title={
										educationList?.length === 0
											? 'Education is required to proceed'
											: null
									}
									onClick={buildprofile ? handleProfileGoNext : handleGoNext}
									className='bg-blueDark rounded-[35px] text-white w-[15rem] p-3'
									type='button'>
									{eduLoading ? <Spin /> : 'Next'}
								</button>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className='mt-10 p-3 lg:p-0 py-[3.5rem] flex flex-col justify-center items-center w-full bg-white'>
						<h3 className='heading_heading_2 w-full text-center mt-[3.75rem]'>
							<span>Education</span>
						</h3>
						<p className='heading_heading_5 mt-[1.75rem] mb-[2.25rem] text-center'>
							Enter your professional/academic qualifications below
						</p>
						<div className='flex items-center justify-center flex-col w-full mt-7'>
							<img
								loading='lazy'
								className='object-contain w-[550px] h-auto'
								src={eduImg}
								alt='education'
							/>

							<button
								onClick={() => setaddEducation(true)}
								className='bg-blueDark rounded-[35px] text-white w-[15rem] p-3 mt-10'
								type='button'>
								Add
							</button>

							{/* <div className='flex items-center justify-center flex-col mt-[0.81rem] w-full'>
								<button
									onClick={buildprofile ? handleProfileGoBack : handleGoBack}
									className='bg-white rounded-[35px] text-blueDark border border-blueDark w-[15rem] p-2'
									type='button'>
									Previous
								</button>
							</div> */}
							<div className='flex flex-col w-full lg:w-[33rem] mt-[5rem] h-full mb-[4.1rem]'>
								<div className='flex items-center'>
									<Checkbox onChange={onSkipChange}></Checkbox>
									<span className='paragraph_paragraph_2 ml-[1.5rem]'>
										Don’t have education experience? Check the box to skip this
										part. This section of your profile will be empty, but you
										can add to it later.
									</span>
								</div>
								<button
									onClick={handleSKip}
									style={{
										opacity: skip ? 1 : 0.4,
									}}
									disabled={!skip}
									className='bg-none text-blueDark font-bold text-[20px] mt-[3.81rem]'>
									Skip
								</button>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}
