import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	authLoading: false,
	neaOfficesLoading: false,
	user: {},
	userList: [],
	isLoggedIn: false,
	userDetails: {},
	organization: {},
	activeUser: {},
	selectedActive: false,
	permissions: [],
	resentOtp: {},
	resetUser: {},
	disability: {},
	token: '',
	chooseAccount: false,
	myInstitutions: [],
	institution: {},
	signUpEmployerDetails: {},
};

export const createUserDetails = createAsyncThunk(
	'authSlice/createUserDetails',
	async (data) => {
		const res = await axios.post(`${url}/nea/saveUser.action`, data);
		return res.data;
	}
);

export const createUserAlt = createAsyncThunk(
	'authSlice/createUserAlt',
	async (data) => {
		const res = await axios.post(`${url}/nea/saveUserAlt.action`, data);
		return res.data;
	}
);

export const resendSignupOtp = createAsyncThunk(
	'authSlice/resendOtp',
	async (nationalId) => {
		const res = await axios.get(
			`${url}/nea/resend_otp.action?nationalId=${nationalId}`
		);
		return res.data;
	}
);

export const resendSignupOtpAlt = createAsyncThunk(
	'authSlice/resendSignupOtpAlt',
	async (nationalId) => {
		const res = await axios.get(
			`${url}/nea/resend_otp_alt.action?nationalId=${nationalId}`
		);
		return res.data;
	}
);

export const verifySignupOtp = createAsyncThunk(
	'authSlice/verifySignupOtp',
	async (data) => {
		const res = await axios.post(`${url}/nea/verify_code.action`, data);
		return res.data;
	}
);

export const verifySignupOtpInst = createAsyncThunk(
	'authSlice/verifySignupOtpInst',
	async (data) => {
		const res = await axios.post(`${url}/nea/verify_code_inst.action`, data);
		return res.data;
	}
);

export const login = createAsyncThunk('authSlice/login', async (data) => {
	const res = await axios.post(
		`${url}/usr/post_authenticate_user.action`,
		data
	);
	return res.data;
});

export const resetPassword = createAsyncThunk(
	'authSlice/resetPassword',
	async (nationalId) => {
		const res = await axios.get(
			`${url}/nea/reset_password.action?nationalId=${nationalId}`
		);
		return res.data;
	}
);

export const updateUserPassword = createAsyncThunk(
	'authSlice/updateUserPassword',
	async (data) => {
		const res = await axios.post(`${url}/nea/updateUserPassword.action`, data);
		return res.data;
	}
);
export const fetchNeaOffices = createAsyncThunk(
	'authSlice/fetchNeaOffices',
	async (neaObj) => {
		const res = await axios.get(
			`${url}/nea/fetchInstitutions.action?instType=NEA_BRANCH&instNearNeaOffice=`
		);
		return res.data.data.result;
	}
);

export const fetchUsersAdmin = createAsyncThunk(
	'authSlice/fetchUsersAdmin',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/nea/fetchUserAdmin.action?usrId=${usrObj?.usrId}`
		);
		return res.data;
	}
);

export const fetchUsers = createAsyncThunk(
	'authSlice/fetchUsers',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/nea/fetchUser.action?usrId=${usrObj?.usrId}`
		);
		return res.data;
	}
);

export const createOtherUser = createAsyncThunk(
	'authSlice/createOtherUser',
	async (data) => {
		const res = await axios.post(`${url}/nea/create_other_users.action`, data);
		return res.data;
	}
);

export const updateUser = createAsyncThunk(
	'authSlice/updateUser',
	async (data) => {
		const res = await axios.post(`${url}/nea/saveUser.action`, data);
		return res.data;
	}
);

export const fetchUserPermissions = createAsyncThunk(
	'authSlice/fetchUserPermissions',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/user/fetchAssignedRoles.action?userId=${usrObj?.usrId}&node=app_${usrObj?.app}`
		);
		return res.data;
	}
);

export const updateUserPermissions = createAsyncThunk(
	'authSlice/updateUserPermissions',
	async (data) => {
		const res = await axios.get(
			`${url}/user/assignRole.action?rolesId=${data?.rolesId}&userId=${data?.userId}&nodeValue=${data?.nodeValue}`
		);
		return res.data;
	}
);

export const verifyTransOtp = createAsyncThunk(
	'authSlice/verifyTransOtp',
	async (data) => {
		const res = await axios.post(`${url}/nea/verify_code.action`, data);
		return res.data;
	}
);

export const fetchCurrentUser = createAsyncThunk(
	'authSlice/fetchCurrentUser',
	async () => {
		const res = await axios.get(`${url}/nea/fetchCurrentUser.action`);
		return res.data;
	}
);

export const fetchUserDisabilitys = createAsyncThunk(
	'authSlice/fetchUserDisabilitys',
	async (data) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchUserDisabilitys.action?disUsrId=${data?.disUsrId}`
		);
		return res.data;
	}
);

export const saveUserDisability = createAsyncThunk(
	'authSlice/saveUserDisability',
	async (data) => {
		const res = await axiosInstance.post(
			`${url}/nea/saveUserDisability.action`,
			data
		);
		return res.data;
	}
);

export const saveReferralCode = createAsyncThunk(
	'authSlice/saveReferralCode',
	async (data) => {
		const res = await axios.post(`${url}/emp/saveReferralCode.action`, data);
		return res.data;
	}
);

export const createInstitution = createAsyncThunk(
	'authSlice/createInstitution',
	async (data) => {
		const res = await axios.post(`${url}/nea/saveInstitution.action`, data);
		return res.data;
	}
);

export const refreshToken = createAsyncThunk(
	'authSlice/refreshToken',
	async (data) => {
		const res = await axios.post(`${url}/usr/refresh_tokens.action`, data);
		return res.data;
	}
);

export const sendUpdateOTP = createAsyncThunk(
	'authSlice/sendUpdateOTP',
	async (data) => {
		const res = await axiosInstance.get(
			`${url}/nea/sendUpdateOTP.action?usrId=${data?.usrId}&type=${
				data?.type ?? ''
			}&userName=${data?.userName ?? ''}`
		);
		return res.data;
	}
);

export const confirmOTP = createAsyncThunk(
	'authSlice/confirmOTP',
	async (data) => {
		const res = await axiosInstance.post(`${url}/nea/confirmOTP.action?`, data);
		return res.data;
	}
);

export const resendOfficialEmailOtp = createAsyncThunk(
	'authSlice/resendOfficialEmailOtp',
	async (email) => {
		const res = await axios.get(
			`${url}/nea/resend_inst_email_otp.action?email=${email}`
		);
		return res.data;
	}
);

export const verifyNationalId = createAsyncThunk(
	'authSlice/verifyNationalId',
	async (data) => {
		const res = await axiosInstance.post(
			`${url}/nea/verifyNationalId.action?`,
			data
		);
		return res.data;
	}
);

export const VerifyUserAdmin = createAsyncThunk(
	'authSlice/VerifyUserAdmin',
	async (data) => {
		const res = await axiosInstance.post(
			`${url}/nea/VerifyUserAdmin.action?`,
			data
		);
		return res.data;
	}
);

export const UpdateUserAdminPassword = createAsyncThunk(
	'authSlice/UpdateUserAdminPassword',
	async (data) => {
		const res = await axiosInstance.post(
			`${url}/nea/UpdateUserAdminPassword.action?`,
			data
		);
		return res.data;
	}
);

export const instAccountRecovery = createAsyncThunk(
	'authSlice/instAccountRecovery',
	async (instEmail) => {
		const res = await axios.get(
			`${url}/nea/instAccountRecovery.action?instEmail=${instEmail}`
		);
		return res.data;
	}
);

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: () => {
			localStorage.clear();
			return { ...initialState };
		},
		setActiveUser: (state, action) => {
			state.activeUser = action.payload;
		},
		setSelectedActive: (state) => {
			state.selectedActive = true;
		},
		closeSelectedActive: (state) => {
			state.selectedActive = false;
			state.activeUser = {};
		},
		handleLoginSession: (state, action) => {
			state.isLoggedIn = action.payload;
		},
		clearUserDetails: (state) => {
			state.userDetails = {};
		},
		handleUserToken: (state, action) => {
			state.token = action.payload;
		},
		handleChooseAccount: (state, action) => {
			state.chooseAccount = action.payload;
		},
		setSignupEmployerDetails: (state, action) => {
			state.signUpEmployerDetails = {
				...state.signUpEmployerDetails,
				...action.payload,
			};
		},
		setSignupUserDetails: (state, action) => {
			state.userDetails = {
				...state.userDetails,
				...action.payload,
			};
		},
		cleanSignupEmployerDetails: (state, action) => {
			state.signUpEmployerDetails = initialState.signUpDetails;
			state.institution = initialState.institution;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(instAccountRecovery.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(instAccountRecovery.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action?.payload?.success) {
					state.institution = action.payload;
				}
			})
			.addCase(instAccountRecovery.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(UpdateUserAdminPassword.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(UpdateUserAdminPassword.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(UpdateUserAdminPassword.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(confirmOTP.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(confirmOTP.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(confirmOTP.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(refreshToken.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(refreshToken.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action?.payload?.success) {
					state.user = action.payload?.jsonData;
					state.myInstitutions = action.payload.data.myInstitution;
				}
			})
			.addCase(refreshToken.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(createInstitution.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(createInstitution.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action?.payload?.success) {
					state.institution = action.payload;
				}
			})
			.addCase(createInstitution.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(login.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action?.payload?.success) {
					state.user = action.payload?.jsonData;
					state.myInstitutions = action.payload.data.myInstitution;
				}
			})
			.addCase(login.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(resetPassword.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(resetPassword.fulfilled, (state, action) => {
				state.authLoading = false;
				state.resetUser = action.payload.jsonData;
			})
			.addCase(resetPassword.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(updateUserPassword.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(updateUserPassword.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(updateUserPassword.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchNeaOffices.pending, (state) => {
				state.neaOfficesLoading = true;
			})
			.addCase(fetchNeaOffices.fulfilled, (state, action) => {
				state.neaOfficesLoading = false;
				state.neaOffices = action.payload;
			})
			.addCase(fetchNeaOffices.rejected, (state) => {
				state.neaOfficesLoading = false;
				state.neaOffices = {};
			})

			.addCase(createUserDetails.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(createUserDetails.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action.payload?.success) {
					state.userDetails = action.payload.jsonData;
				}
			})

			.addCase(createUserDetails.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(createUserAlt.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(createUserAlt.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action.payload?.success) {
					state.userDetails = action.payload.jsonData;
				}
			})
			.addCase(createUserAlt.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(verifyNationalId.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(verifyNationalId.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action.payload?.success) {
					state.userDetails = action.payload.jsonData;
				}
			})
			.addCase(verifyNationalId.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(VerifyUserAdmin.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(VerifyUserAdmin.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action.payload?.success) {
					state.userDetails = action.payload.jsonData;
				}
			})
			.addCase(VerifyUserAdmin.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(resendSignupOtpAlt.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(resendSignupOtpAlt.fulfilled, (state, action) => {
				state.authLoading = false;
			})
			.addCase(resendSignupOtpAlt.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(resendSignupOtp.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(resendSignupOtp.fulfilled, (state, action) => {
				state.authLoading = false;
			})
			.addCase(resendSignupOtp.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(verifySignupOtpInst.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(verifySignupOtpInst.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(verifySignupOtpInst.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(verifySignupOtp.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(verifySignupOtp.fulfilled, (state, action) => {
				state.authLoading = false;
				state.user = action.payload.jsonData;
			})
			.addCase(verifySignupOtp.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(createOtherUser.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(createOtherUser.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(createOtherUser.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchUsers.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.authLoading = false;
				state.userList = action?.payload?.data?.result;
			})
			.addCase(fetchUsers.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchUsersAdmin.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchUsersAdmin.fulfilled, (state, action) => {
				state.authLoading = false;
				state.userList = action?.payload?.data?.result;
			})
			.addCase(fetchUsersAdmin.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(updateUser.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action?.payload?.success) {
					state.user = action?.payload?.jsonData;
				}
			})
			.addCase(updateUser.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchUserPermissions.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchUserPermissions.fulfilled, (state, action) => {
				state.authLoading = false;
				state.permissions = action?.payload;
			})
			.addCase(fetchUserPermissions.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(updateUserPermissions.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(updateUserPermissions.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(updateUserPermissions.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(verifyTransOtp.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(verifyTransOtp.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(verifyTransOtp.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchCurrentUser.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchCurrentUser.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action?.payload?.success) {
					state.user = action?.payload?.jsonData;
				}
			})
			.addCase(fetchCurrentUser.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(fetchUserDisabilitys.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(fetchUserDisabilitys.fulfilled, (state, action) => {
				state.authLoading = false;
				if (action?.payload?.success) {
					state.disability = action?.payload?.data?.result[0];
				}
			})
			.addCase(fetchUserDisabilitys.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(saveUserDisability.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(saveUserDisability.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(saveUserDisability.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(saveReferralCode.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(saveReferralCode.fulfilled, (state) => {
				state.authLoading = false;
			})
			.addCase(saveReferralCode.rejected, (state) => {
				state.authLoading = false;
			});
	},
});

export default authSlice.reducer;
export const {
	logout,
	setActiveUser,
	setSelectedActive,
	closeSelectedActive,
	handleLoginSession,
	clearUserDetails,
	handleUserToken,
	handleChooseAccount,
	setSignupEmployerDetails,
	setSignupUserDetails,
	cleanSignupEmployerDetails,
} = authSlice.actions;
