import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Steps } from 'antd';
import EducationInfo from '../../signup/profile_setup/steps/EducationInfo';
import ExperienceInfo from '../../signup/profile_setup/steps/ExperienceInfo';
import NextOfKinInfo from '../../signup/profile_setup/steps/NextOfKinInfo';
import SkillInfo from '../../signup/profile_setup/steps/SkillInfo';
import DocsInfo from '../../signup/profile_setup/steps/DocsInfo';

export default function BuildProfile() {
	const { stepCurrent } = useSelector((state) => state.profile);

	const view = useRef()

	const [current, setcurrent] = useState(stepCurrent);

	const onChange = (value) => {
		setcurrent(value);
		view?.current?.scrollIntoView()
	};

	const steps = [
		{
			title: 'Education',
			key: 1,
			content: <EducationInfo buildprofile={true} />,
			icon: <span></span>,
		},
		{
			title: 'Experience',
			key: 2,
			content: <ExperienceInfo buildprofile={true} />,
			icon: <span></span>,
		},
		// {
		// 	title: 'Skills/Training',
		// 	key: 3,
		// 	content: <SkillInfo buildprofile={true} />,
		// 	icon: <span></span>,
		// },
		{
			title: 'Statutory Documents',
			key: 3,
			content: <DocsInfo buildprofile={true} />,
			icon: <span></span>,
		},
		{
			title: 'Next of Kin',
			key: 4,
			content: <NextOfKinInfo buildprofile={true} />,
			icon: <span></span>,
		},
	];

	const items = steps.map((item) => ({
		key: item.key,
		title: item.title,
		icon: item.icon,
	}));

	useEffect(() => {
		view?.current?.scrollIntoView()
	}, []);

	useEffect(() => {}, [current]);

	useEffect(() => {
		setcurrent(stepCurrent);
	}, [stepCurrent]);

	return (
		<>
			<div className='w-full p-3 lg:p-0 lg:w-[95%] lg:mx-[42px] '>
				<h2 ref={view} className='setup-title mt-[2rem] text-center'>
					<span className='text-[#147CBC]'>Edit</span> profile
				</h2>
				<p className='heading_heading_5 mt-[1rem] text-center'>
					Choose any of the sections below to edit.
				</p>

				<div className='hidden lg:flex lg:w-full mt-[4.5rem] justify-center items-center'>
					<div className='w-[50rem]'>
						<Steps
							onChange={onChange}
							className='w-full'
							current={current}
							size='small'
							labelPlacement='vertical'
							items={items}
						/>
					</div>
				</div>

				<div>{steps[current].content}</div>
			</div>
		</>
	);
}
