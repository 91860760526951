import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Select, Spin } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../components/signup/Header';
import { fetchRegistrationDocs } from '../../features/global/globalSlice';
import PhoneInput from 'react-phone-input-2';
import {
	createUserAlt,
	setSignupUserDetails,
	verifyNationalId,
} from '../../features/auth/authSlice';
import { customToast } from '../../notifications';

export default function AdminAccount() {
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { authLoading, userDetails, institution } = useSelector(
		(state) => state.auth
	);

	console.log(userDetails);
	

	const { globalLoading, regDocs } = useSelector((state) => state.global);

	const [docHolder, setDocHolder] = useState();
	const [docList, setdocList] = useState(regDocs);
	const [verified, setverified] = useState(null);

	const onFinish = async (data) => {
		data.usrAltMobileNumber =
			data?.usrAltMobileNumber?.charAt(3) === '0'
				? data.usrAltMobileNumber?.replace('0', '')
				: data.usrAltMobileNumber;

		const res = await dispatch(
			createUserAlt({
				...data,
				usrAltId: userDetails?.usrAltId ?? null,
				usrAltEncryptedPassword: null,
				usrAltRoleId: institution?.jsonData?.instType === 'EMPLOYER' ? 5 : 3,
				usrAltInstId: institution?.jsonData?.instId,
				usrAltDocType: data?.usrAltDocType,
			})
		);
		if (res?.payload?.success) {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'success',
			});
			await navigate('/verify-otp');
		} else {
			await customToast({
				content: res?.payload?.messages?.message,
				bdColor: 'error',
			});
		}
	};

	async function handleFetchRegDocs() {
		await dispatch(fetchRegistrationDocs());
	}

	async function handleVerifyUser(val) {        
		const verObj = {
			usrAltNationalId: val,
		};

		const res = await dispatch(verifyNationalId(verObj));
		if (res?.payload?.success) {
			setverified(true);
		} else {
			setverified(false);
		}
	}

	const handleValuesChange = async (allValues) => {
		await dispatch(setSignupUserDetails(allValues));
	};

	useEffect(() => {
		handleFetchRegDocs();
	}, []);

    useEffect(() => {
      if (verified === true) {
        form.setFieldsValue(userDetails)
      }
    }, [verified])
    

	useEffect(() => {}, [docList, userDetails]);

	useEffect(() => {
		setdocList(regDocs);
	}, [regDocs]);

	return (
		<>
			<div className='sticky top-0 w-full z-[1000]'>
				<Header />
			</div>

			<div className='slanted-background !h-auto'>
				<div className='flex items-center justify-center'>
					<div className='w-full lg:w-[61.25rem]'>
						<div className='flex flex-col w-full px-3 lg:px-[52px] p-3'>
							<div className='bg-white mt-[3.75rem] pt-[3.75rem] pb-[3.19rem] rounded-[.5625rem]'>
								<h2 className='setup-title text-center'>
									Fill in your personal details
								</h2>
								<p className='heading_heading_5 mt-[1rem] text-center'>
									You will be registered as an account administrator{' '}
								</p>
							</div>

							<Form
								layout='vertical'
								ref={formRef}
								name='control-ref'
								onFinish={onFinish}
								style={{
									width: '100%',
								}}
								form={form}
								onValuesChange={handleValuesChange}
								initialValues={{
									...userDetails,
								}}>
								<div className='mt-10 p-3 !pr-0 lg:!pr-10 lg:p-0 py-[3.5rem] flex flex-col justify-center w-full bg-white'>
									<div className='my-[2.25rem] px-[1.37rem] flex items-start justify-start '>
										<div className='w-[3.45rem]'>
											<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
												<span className='paragraph_paragraph_bb08'>3</span>
											</div>
										</div>

										<div className='w-full flex flex-col ml-[1.31rem]'>
											<span className='paragraph_paragraph_bb13'>
												Personal details
											</span>

											<div className='grid grid-cols-1 lg:grid-cols-2 gap-[1.25rem] w-full'>
												<Form.Item
													className='table-form-item'
													name='usrAltDocType'
													label='Choose ID'
													rules={[
														{
															required: true,
															message: 'Please select document type',
														},
													]}>
													<Select
														loading={globalLoading}
														suffixIcon={
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'>
																<path
																	d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
																	fill='#212121'
																/>
															</svg>
														}
														options={docList?.map((item) => {
															return {
																label: item?.rgdName,
																value: item?.rgdName,
															};
														})}
														onChange={(value) => {
															setDocHolder(value);
														}}
													/>
												</Form.Item>

												<Form.Item
													className='table-form-item'
													name='usrAltNationalId'
													label={`Enter ${
														docHolder ? docHolder : 'Document ID'
													}`}
													rules={[
														{
															required: true,
															message: 'Please add document ID number',
														},
														// {
														// 	pattern: /^[0-9]$/,
														// 	message: '',
														// },
													]}>
													<Input
														onChange={(e) => handleVerifyUser(e.target.value)}
														disabled={!docHolder}
														readOnly={userDetails?.usrAltId}
														className='input'
													/>
												</Form.Item>
											</div>

											<div className='grid grid-cols-1 gap-[1.25rem] mt-[1.25rem] w-full'>
												<Form.Item
													validateTrigger='onBlur'
													className='table-form-item'
													name='usrAltFirstName'
													label='First name'
													rules={[
														{
															required: true,
															message: 'Please add first name',
														},
														{
															pattern: /^[a-zA-Z0-9_]+$/,
															message: 'Only first name is required',
														},
													]}>
													<Input className='input' />
												</Form.Item>

												<Form.Item
													className='table-form-item'
													name='usrAltEmail'
													label='Email address'
													extra='Enter your personal email address, separate from the official email'
													rules={[
														{
															required: true,
															message: 'Please add email',
														},
													]}>
													<Input type='email' className='input' />
												</Form.Item>

												<Form.Item
													className='table-form-item'
													name='usrAltMobileNumber'
													label='Phone Number'
													rules={[
														{
															required: true,
															message: 'Please add office phone number',
														},
													]}>
													<PhoneInput
														country='ke'
														countryCodeEditable={false}
														className='input'
													/>
												</Form.Item>
											</div>
										</div>
									</div>

									<div className='text-center mt-[3rem]'>
										<button
											disabled={authLoading}
											className='text-white text-[18px] font-bold font-dmSans
                    w-[299px] h-[53px] px-16 py-[19px] bg-blueDark rounded-[38px] 
                    justify-center items-center gap-2.5 inline-flex'>
											{authLoading ? <Spin className='text-white' /> : 'Next'}
										</button>
									</div>

									<div className='text-center mt-[20px] mb-[50px]'>
										<div
											className='cursor-pointer text-blueDark text-xl font-normal font-dmSans leading-normal
				w-[299px] h-[53px] pl-[18px] pr-[17.20px] pt-2 pb-[7.50px] bg-white rounded-[35px] 
				shadow border-2 border-blueDark justify-center items-center inline-flex'
											onClick={() => navigate(-1)}>
											Back
										</div>
									</div>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
