import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import './App.css';
import './load.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout, updateUser } from './features/auth/authSlice';
import Components from './pages/components/Components';
import Step3 from './pages/signup/Step3';
import Step2 from './pages/signup/Step2';
import Login from './pages/signin/Login';
import ResetPassword from './pages/signin/ResetPassword';
import VerifyOtp from './pages/signin/VerifyOtp';
import NewPassword from './pages/signin/NewPassword';
import MainProfile from './pages/profile/MainProfile';
import BuildProfile from './pages/profile/build_profile/BuildProfile';
import DashBoard from './pages/dashboard/Dashboard';
import JobVacancy from './pages/job_vacancy/JobVacancy';
import JobInfo from './pages/job_info/JobInfo';
import ApplicationStatus from './pages/application_status/ApplicationStatus';
import ForeignJobClearance from './pages/foreign_clearance/ForeignClearance';
import Counselor from './pages/counselor/Counselor';
import MainCVBuilder from './pages/cv_builder/MainCVBuilder';
import CreateCV from './pages/cv_builder/CreateCV';
import CreateCVL from './pages/cv_builder/CreateCVL';
import MigrantWorker from './pages/signup/MigrantWorker';
import Inbox from './pages/inbox/Inbox';
import ComposeMessage from './pages/inbox/inbox_files/ComposeMessage';
import Feedback from './pages/home/Feedback';
import HomeJobs from './pages/home/HomeJobs';
import VerifiedAgencies from './pages/home/VerifiedAgencies';
import DistressChoice from './pages/home/DistressChoice';
import SOSDistress from './pages/home/SOSDistress';
import ComplaintDistress from './pages/home/ComplaintDistress';
import Registration from './pages/signup/Registration';
import Header from './pages/components/signup/Header';
import ProfileSetup from './pages/signup/profile_setup/ProfileSetup';
import ForeignJobDetails from './pages/foreign_clearance/ForeignJobDetails';
import Accounts from './pages/accounts/Accounts';
import Referral from './pages/signup/Referral';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Details from './pages/signup_agency/Details';
import Otp from './pages/signup_agency/Otp';
import PasswordAgency from './pages/signup_agency/PasswordFields';
import AgencyTerms from './components/AgencyTerms';
import EmployerRegistration from './pages/signup_employer/EmployerRegistration';
import AuthVerify from './AuthVerify';
import DataConsent from './DataConsent';
import SharedJob from './pages/job_info/SharedJob';
import { fetchNotifications } from './features/global/globalSlice';
import axiosInstance from './instance';
import CounselorHistory from './pages/counselor/CounselorHistory';
import MainHome from './pages/home/new_home/MainHome';
import CVTemplateSelector from './pages/cv_builder/templates/CVTemplateSelector';
import CoverTemplateSelector from './pages/cv_builder/templates/CoverTemplateSelector';
import RegistrationType from './pages/signup/RegistrationType';
import Settings from './pages/settings/Settings';
import SecuritySettings from './pages/settings/SecuritySettings';
import NotificationSettings from './pages/settings/NotificationSettings';
import SingleNotificationSettings from './pages/settings/SingleNotificationSetting';
import AddJobSeeker from './pages/signup/AddJobSeeker';
import VerifyInstEmail from './pages/signup/VerifyInstEmail';
import AdminAccount from './pages/signup/AdminAccount';
import AccountRecovery from './pages/signin/AccountRecovery';
import AttestationProcess from './pages/foreign_clearance/AttestationProcess';
import CheckDistress from './pages/home/CheckDistress';

function App() {
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const { isLoggedIn, user, token, chooseAccount } = useSelector(
		(state) => state.auth
	);

	const [isUserLoggedIn, setisUserLoggedIn] = useState(isLoggedIn);
	const [usrObj, setusrObj] = useState(user);
	const [usrToken, setusrToken] = useState(token);

	let location = window.location.href;

	axiosInstance.defaults.headers.common['Authorization'] = usrToken;

	async function handleUpdateUser() {
		const values = {
			usrId: user?.usrId,
			usrNationalId: user?.usrNationalId,
		};
		await dispatch(updateUser(values));
	}

	async function handleFetchNotifications() {
		let excludedPaths = window.location.href.split('#').includes('/accounts');
		if (
			user?.usrId &&
			user?.usrStatus === 'PROFILE_COMPLETE' &&
			!excludedPaths
		) {
			await dispatch(fetchNotifications(user?.usrId));
		}
	}

	async function handleLogout() {
		await dispatch(logout());
		await localStorage.clear();
	}

	useEffect(() => {
		axiosInstance.defaults.headers.common['Authorization'] = usrToken;
	}, [usrObj, usrToken]);

	useEffect(() => {
		if (isUserLoggedIn) {
			handleUpdateUser();
		}
	}, [location, isUserLoggedIn]);

	useEffect(() => {
		handleFetchNotifications();
	}, [pathname, user]);

	useEffect(() => {
		setisUserLoggedIn(isLoggedIn);
		setusrObj(user);
		setusrToken(token);
	}, [isLoggedIn, user, token, chooseAccount]);

	if (!isUserLoggedIn) {
		return (
			<>
				<div className='wrapper overflow-x-hidden h-[100vh] overflow-y-scroll'>
					<Routes>
						<Route path='*' element={<Navigate to='/login' replace />} />
						<Route exact path='/' element={<MainHome />} />
						<Route exact path='/home' element={<MainHome />} />
						<Route exact path='/registration' element={<Registration />} />
						<Route
							exact
							path='/registration/type'
							element={<RegistrationType />}
						/>
						<Route exact path='/registration-agency' element={<Details />} />
						<Route
							exact
							path='/registration-employer'
							element={<EmployerRegistration />}
						/>
						<Route exact path='/components' element={<Components />} />
						<Route
							exact
							path='/registration-confirmation'
							element={<Step2 />}
						/>
						<Route exact path='/migrant-worker' element={<MigrantWorker />} />
						<Route exact path='/referral' element={<Referral />} />

						<Route exact path='/login' element={<Login />} />
						<Route exact path='/reset-password' element={<ResetPassword />} />
						<Route exact path='/verify-otp-signin' element={<VerifyOtp />} />
						<Route exact path='/verify-otp' element={<Otp />} />
						<Route exact path='/verify-email' element={<VerifyInstEmail />} />
						<Route exact path='/admin-account' element={<AdminAccount />} />
						<Route exact path='/new-password' element={<NewPassword />} />
						<Route exact path='/set-password' element={<PasswordAgency />} />
						{/* <Route exact path='/new-agency-password' element={<NewPassword />} /> */}
						<Route
							exact
							path='/distress-type'
							element={<DistressChoice isUserLoggedIn={isUserLoggedIn} />}
						/>
						<Route
							exact
							path='/distress-SOS'
							element={<SOSDistress isUserLoggedIn={isUserLoggedIn} />}
						/>
						<Route
							exact
							path='/distress-complaint'
							element={<ComplaintDistress isUserLoggedIn={isUserLoggedIn} />}
						/>
						<Route
							exact
							path='/distress-status'
							element={<CheckDistress isUserLoggedIn={isUserLoggedIn} />}
						/>
						<Route exact path='/feedback' element={<Feedback />} />
						<Route exact path='/home/jobs' element={<HomeJobs />} />
						<Route exact path='/home/agencies' element={<VerifiedAgencies />} />
						<Route
							exact
							path='/nea-privacy-policy'
							element={<PrivacyPolicy />}
						/>
						<Route exact path='/terms-of-service' element={<Terms />} />
						<Route
							exact
							path='/agency/terms-of-service'
							element={<AgencyTerms />}
						/>
						<Route exact path='/data-consent' element={<DataConsent />} />
						<Route
							exact
							path='/shared-job/:id/:position'
							element={<SharedJob />}
						/>
						<Route
							exact
							path='/home/job-info/:id/:position'
							element={<SharedJob />}
						/>
						<Route exact path='/update-profile-password' element={<Step3 />} />
						<Route
							exact
							path='/account-recovery'
							element={<AccountRecovery />}
						/>
					</Routes>
				</div>
			</>
		);
	} else if (
		isUserLoggedIn &&
		usrObj?.usrStatus === 'ACTIVE' &&
		chooseAccount === false
	) {
		return (
			<>
				<div className='wrapper overflow-x-hidden h-[100vh] overflow-y-scroll'>
					<Header isUserLoggedIn={isUserLoggedIn} />
					<Routes>
						<Route
							path='*'
							element={<Navigate to='/profile-setup' replace />}
						/>
						<Route exact path='/profile-setup' element={<ProfileSetup />} />
						{/* <Route exact path='/update-profile-password' element={<Step3 />} /> */}
						<Route
							exact
							path='/nea-privacy-policy'
							element={<PrivacyPolicy />}
						/>
						<Route exact path='/terms-of-service' element={<Terms />} />
						<Route
							exact
							path='/agency/terms-of-service'
							element={<AgencyTerms />}
						/>
						<Route exact path='/data-consent' element={<DataConsent />} />
					</Routes>
				</div>
			</>
		);
	} else if (isUserLoggedIn && chooseAccount) {
		return (
			<>
				<Header isUserLoggedIn={isLoggedIn} />
				<Routes>
					<Route path='*' element={<Navigate to='/accounts' replace />} />
					<Route exact path='/accounts' element={<Accounts />} />
					<Route
						exact
						path='/accounts/create-jobseeker'
						element={<AddJobSeeker />}
					/>
					<Route exact path='/nea-privacy-policy' element={<PrivacyPolicy />} />
					<Route exact path='/terms-of-service' element={<Terms />} />
					<Route
						exact
						path='/agency/terms-of-service'
						element={<AgencyTerms />}
					/>
					<Route exact path='/data-consent' element={<DataConsent />} />
				</Routes>
			</>
		);
	} else {
		return (
			<div className='wrapper'>
				<DashboardLayout isSideBarEnabled={isUserLoggedIn}>
					<Routes>
						<Route path='*' element={<Navigate to='/login' replace />} />
						<Route
							exact
							path='/nea-privacy-policy'
							element={<PrivacyPolicy />}
						/>
						<Route exact path='/terms-of-service' element={<Terms />} />
						<Route exact path='/' element={<DashBoard />} />
						<Route exact path='/login' element={<DashBoard />} />
						<Route exact path='/home' element={<DashBoard />} />
						<Route exact path='/components' element={<Components />} />
						<Route exact path='/profile' element={<MainProfile />} />
						<Route exact path='/profile-builder' element={<BuildProfile />} />
						<Route exact path='/cv-cover-letter' element={<MainCVBuilder />} />
						<Route
							exact
							path='/cv-builder/choose-template'
							element={<CVTemplateSelector />}
						/>
						<Route
							exact
							path='/cover-letter-builder/choose-template'
							element={<CoverTemplateSelector />}
						/>
						<Route
							exact
							path='/cv-cover-letter/create/resume'
							element={<CreateCV />}
						/>
						<Route
							exact
							path='/cv-cover-letter/edit/resume'
							element={<CreateCV />}
						/>

						<Route
							exact
							path='/cv-cover-letter/create/cover-letter'
							element={<CreateCVL />}
						/>
						<Route
							exact
							path='/cv-cover-letter/edit/cover-letter'
							element={<CreateCVL />}
						/>

						<Route exact path='/job-vacancies' element={<JobVacancy />} />
						<Route exact path='/job-info/:id/:position' element={<JobInfo />} />
						<Route
							exact
							path='/shared-job/:id/:position'
							element={<SharedJob />}
						/>
						<Route
							exact
							path='/application-status'
							element={<ApplicationStatus />}
						/>
						<Route
							exact
							path='/foreign-job-clearance'
							element={<ForeignJobClearance />}
						/>
						<Route
							exact
							path='/attestation-process/new'
							element={<ForeignJobDetails />}
						/>
						<Route
							exact
							path='/attestation-process/details'
							element={<AttestationProcess />}
						/>

						<Route exact path='/find-counselor' element={<Counselor />} />
						<Route
							exact
							path='/sessions/history'
							element={<CounselorHistory />}
						/>
						<Route
							exact
							path='/distress-type'
							element={<DistressChoice isUserLoggedIn={isUserLoggedIn} />}
						/>
						<Route
							exact
							path='/distress-SOS'
							element={<SOSDistress isUserLoggedIn={isUserLoggedIn} />}
						/>
						<Route
							exact
							path='/distress-complaint'
							element={<ComplaintDistress isUserLoggedIn={isUserLoggedIn} />}
						/>
						<Route
							exact
							path='/distress-status'
							element={<CheckDistress isUserLoggedIn={isUserLoggedIn} />}
						/>
						<Route
							exact
							path='/notifications/compose'
							element={<ComposeMessage />}
						/>
						<Route exact path='/notifications/inbox' element={<Inbox />} />
						<Route exact path='/data-consent' element={<DataConsent />} />

						<Route exact path='/settings' element={<Settings />} />
						<Route
							exact
							path='/settings/security'
							element={<SecuritySettings />}
						/>
						<Route
							exact
							path='/settings/notifications'
							element={<NotificationSettings />}
						/>
						<Route
							exact
							path='/settings/notifications/update'
							element={<SingleNotificationSettings />}
						/>
					</Routes>
					<AuthVerify logOut={handleLogout} />
				</DashboardLayout>
			</div>
		);
	}
}

export default App;
