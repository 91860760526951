import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Progress, Steps } from 'antd';
import PersonalInfo from './steps/PersonalInfo';
import EducationInfo from './steps/EducationInfo';
import ExperienceInfo from './steps/ExperienceInfo';
import SkillInfo from './steps/SkillInfo';
import NextOfKinInfo from './steps/NextOfKinInfo';
import { handleRegStepCurrent } from '../../../features/profile/profileSlice';

export default function ProfileSetup() {
	const dispatch = useDispatch();

	const { regStepCurrent } = useSelector((state) => state.profile);
	const { user } = useSelector((state) => state.auth);

	const [current, setcurrent] = useState(0);

	let num = user?.usrType === 'MIGRANT_WORKER' ? 3 : 4;

	const steps =
		user?.usrType === 'MIGRANT_WORKER'
			? [
					{
						title: 'Education',
						key: 1,
						content: <EducationInfo />,
						icon: <span>1</span>,
					},
					{
						title: 'Experience',
						key: 2,
						content: <ExperienceInfo />,
						icon: <span>2</span>,
					},
					{
						title: <span className='ml-4'>Skills</span>,
						key: 3,
						content: <SkillInfo />,
						icon: <span>3</span>,
					},
					{
						title: 'Next of Kin',
						key: 4,
						content: <NextOfKinInfo />,
						icon: <span>4</span>,
					},
			  ]
			: [
					{
						title: 'Personal Info',
						key: 1,
						content: <PersonalInfo />,
						icon: <span>1</span>,
					},
					{
						title: 'Education',
						key: 2,
						content: <EducationInfo />,
						icon: <span>2</span>,
					},
					{
						title: 'Experience',
						key: 3,
						content: <ExperienceInfo />,
						icon: <span>3</span>,
					},
					{
						title: <span className='ml-4'>Skills</span>,
						key: 4,
						content: <SkillInfo />,
						icon: <span>4</span>,
					},
					{
						title: 'Next of Kin',
						key: 5,
						content: <NextOfKinInfo />,
						icon: <span>5</span>,
					},
			  ];

	const items = steps.map((item) => ({
		key: item.key,
		title: item.title,
		icon: item.icon,
	}));

	useEffect(() => {
		setcurrent(0);
		dispatch(handleRegStepCurrent(0));
	}, []);

	useEffect(() => {}, [current]);

	useEffect(() => {
		setcurrent(regStepCurrent);
	}, [regStepCurrent]);

	return (
		<>
			<div className='w-full'>
				<Progress
					type='line'
					className='profile-progress'
					showInfo={false}
					percent={(3 / num) * 100}
				/>

				<div className='flex items-center justify-center'>
					<div className='w-full lg:w-[60.4375rem]'>
						<div className='flex flex-col w-full px-3 lg:px-[52px] p-3'>
							<h2 className='setup-title mt-[2rem]'>
								Welcome,{' '}
								<span className='text-[#147CBC] capitalize'>
									{user?.usrFirstName}
								</span>
							</h2>
							<p className='heading_heading_5 mt-[1rem]'>
								Let’s build a profile that will stand out to employers
							</p>

							<div className='hidden lg:block lg:w-full mt-4 p-6'>
								<Steps
									className='w-[105%]'
									current={current}
									size='small'
									labelPlacement='vertical'
									items={items}
								/>
							</div>

							<div className=''>{steps[current].content}</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
